<template>
  <div class="dashboard">
    <a-form-model layout="vertical" :model="form">
      <!-- Form -->
      <a-row>
        <a-col :span="6" class="search-warehouse">
          <a-form-model-item :label="$t('order.warehouse')">
            <a-select
              v-model="form.warehouse"
              mode="multiple"
              allow-clear
              show-search
              :placeholder="$t('topBar.all_Warehouse')"
              :options="warehouseList"
              :max-tag-count="1"
              :filter-option="filterOption"
              :loading="loading.warehouse"
              :disabled="loading.warehouse"
              option-filter-prop="children"
              @change="initSummary"
            />
          </a-form-model-item>
        </a-col>
      </a-row>

      <!-- Status & Total -->
      <section id="transaction" class="mb-4">
        <a-row :gutter="16">
          <!-- Status Transaction -->
          <a-col :span="18">
            <a-card class="pbz-card" :bordered="false">
              <div class="pbz-card-title mb-30">
                <h3>{{ $t('dashboardContent.transaction_status') }}</h3>
                <p class="mb-0">
                  {{ $t('dashboardContent.detail_status') }}
                </p>
              </div>
              <a-row :gutter="5">
                <a-col v-for="status in statusList" :key="status.id" :span="4">
                  <div class="box-status">
                    <div class="box-status-title">
                      <span class="box-status-span" :style="{ backgroundColor: status.color }">
                        &nbsp;
                      </span>
                      <span>{{ $t(`order.filterState.${status.name}`) }}</span>
                    </div>
                    <div class="box-status-content">
                      <a-skeleton :loading="loading.status" :paragraph="{ rows: 0 }" active>
                        {{ formatNumber(status.value) }}
                      </a-skeleton>
                    </div>
                    <span class="box-status-url" @click="detailOrder(status.name, status.url)">
                      Detail <a-icon type="right" />
                    </span>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>

          <!-- Total SKU & OOS -->
          <a-col :span="6">
            <a-card v-for="(sku, index) in skuList" :key="sku.id" :class="['pbz-card', index == 0 ? 'mb-3' : '']" :bordered="false">
              <div class="pbz-card-title">
                <h3>Total SKU {{ sku.name === 'oos' ? 'OOS' : '' }}</h3>
                <p class="mb-2">
                  {{ $t(`dashboardContent.total.${sku.name}`) }}
                </p>
              </div>
              <div class="pbz-card-content">
                <a-skeleton :loading="loading.sku" :paragraph="{ rows: 0 }" active>
                  {{ formatNumber(sku.value) }}
                </a-skeleton>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </section>

      <!-- Today Summary -->
      <section id="summary">
        <dashboard-summary
          summary-name="is_today"
          :loading="loading.todaySummary"
          :summary-list="summaryTodayList"
          :format-number="formatNumber"
          :convert-number="convertNumber"
        />
      </section>

      <hr style="margin: 1.5rem 0" />

      <!-- Report -->
      <section id="report">
        <div class="d-flex">
          <h3 class="mb-2">
            {{ $t('dashboardContent.report') }}
          </h3>
          <div class="d-flex ml-auto">
            <div class="report-date">
              {{ $t('utils.date') }}
            </div>
            <div class="w-265">
              <a-form-model-item>
                <a-range-picker
                  v-model="form.report_date"
                  :ranges="ranges"
                  :format="dateFormat"
                  :allow-clear="false"
                  :placeholder="[$t('dashboardContent.start_date'), $t('dashboardContent.end_date')]"
                  :disabled-date="disabledDate"
                  @change="initReport"
                >
                  <a-icon slot="suffixIcon" type="calendar" style="color: #999999;" />
                </a-range-picker>
              </a-form-model-item>
            </div>
          </div>
        </div>

        <!-- Report Form -->
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-model-item :label="$t('order.business')">
              <a-select
                v-model="form.report_business"
                mode="multiple"
                allow-clear
                show-search
                :placeholder="$t('topBar.all_Business')"
                :options="businessList"
                :max-tag-count="1"
                :filter-option="filterOption"
                :loading="loading.business"
                :disabled="loading.business"
                option-filter-prop="children"
                @change="fetchWarehouseList('report'); fetchChannels(); initReport()"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item :label="$t('order.warehouse')">
              <a-select
                v-model="form.report_warehouse"
                mode="multiple"
                allow-clear
                show-search
                :placeholder="$t('topBar.all_Warehouse')"
                :options="reportWarehouseList"
                :max-tag-count="1"
                :filter-option="filterOption"
                :loading="loading.warehouse"
                :disabled="loading.warehouse"
                option-filter-prop="children"
                @change="initReport"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="Channel">
              <a-select
                v-model="form.report_channel"
                mode="multiple"
                allow-clear
                show-search
                :placeholder="$t('topBar.all_Channel')"
                :options="channelList"
                :max-tag-count="1"
                :filter-option="filterOption"
                :loading="loading.channelList"
                :disabled="loading.channelList"
                option-filter-prop="children"
                @change="initReport"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item :label="$t('utils.currency')">
              <a-select
                v-model="form.report_currency"
                show-search
                :placeholder="$t('utils.currency')"
                :options="currencyList"
                :filter-option="filterOption"
                option-filter-prop="children"
                @change="initReport"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- Summary -->
        <dashboard-summary
          class="mb-3"
          summary-name="not_today"
          :loading="loading.reportSummary"
          :summary-list="summaryList"
          :format-number="formatNumber"
          :convert-number="convertNumber"
        />

        <!-- Sales Overview -->
        <a-row :gutter="16" class="mb-3">
          <!-- Data Sales Overview -->
          <a-col :span="8">
            <a-card class="pbz-card" :bordered="false">
              <div class="pbz-card-title mb-4">
                <a-row :gutter="8">
                  <a-col :span="16">
                    <h3>{{ $t('dashboardContent.salesOverview') }}</h3>
                  </a-col>
                  <a-col :span="8">
                    <p class="mb-0 text-right">
                      {{ $t('dashboardContent.compare') }}
                    </p>
                  </a-col>
                </a-row>
                <a-row :gutter="8">
                  <a-col :span="8">
                    <p class="mt-2 mb-0">
                      &nbsp;
                    </p>
                  </a-col>
                  <a-col :span="16">
                    <a-range-picker
                      v-model="form.report_comparison"
                      :ranges="ranges"
                      :format="dateFormat"
                      :placeholder="[$t('dashboardContent.start_date'), $t('dashboardContent.end_date')]"
                      :disabled-date="disabledDate"
                      @change="fetchSummary('comparison')"
                    >
                      <a-icon slot="suffixIcon" type="calendar" style="color: #999999;" />
                    </a-range-picker>
                  </a-col>
                </a-row>
              </div>
              <a-card class="pbz-card" style="box-shadow: none">
                <p class="mb-0 text-right fs-13">
                  <template v-if="form.report_comparison.length">
                    vs {{ checkDate() }}
                  </template>
                  <template v-else>
                    &nbsp;
                  </template>
                </p>
                <a-row v-for="sale in sales.values" :key="sale.id" :gutter="8" class="pbz-card-sales">
                  <a-col :span="16">
                    <div class="pbz-card-title">
                      <p class="mb-0">
                        {{ sale.name }}
                      </p>
                    </div>
                    <div class="pbz-card-content fs-16">
                      <a-skeleton :loading="loading.reportSummary" :paragraph="{ rows: 0 }" active>
                        {{ `${sale.prefix} ${formatNumber(sale.value)}` }}
                      </a-skeleton>
                    </div>
                  </a-col>
                  <a-col :span="8">
                    <div v-if="form.report_comparison.length" class="pbz-card-content text-right">
                      <a-skeleton :loading="loading.comparisonSummary" :paragraph="{ rows: 0 }" active>
                        <p class="mt-4 mb-0 fs-12" :style="{ color: +sale.percent < 0 ? '#f5222d' : '#52c41a' }">
                          <a-icon v-if="+sale.percent > 0" type="arrow-up" />
                          <a-icon v-if="+sale.percent < 0" type="arrow-down" />
                          {{ formatNumber(sale.percent.toFixed(2).toString().replace('-', '')) }}%
                        </p>
                      </a-skeleton>
                    </div>
                  </a-col>
                </a-row>
              </a-card>
            </a-card>
          </a-col>

          <!-- Sales Overview Graph -->
          <a-col :span="16">
            <a-card class="pbz-card" :bordered="false">
              <div class="pbz-card-title mb-3">
                <h3>{{ $t('dashboardContent.sales_overview_graph') }}</h3>
              </div>
              <div class="dashboard-graph">
                <a-spin :spinning="loading.sales">
                  <line-chart
                    :chart-data="sales.chartData"
                    :options="sales.chartOptions"
                  />
                </a-spin>
              </div>
            </a-card>
          </a-col>
        </a-row>

        <!-- Channel, Warehouse, Logistic & Payment Method -->
        <a-row :gutter="16" class="mb-3">
          <a-col v-for="chart in chartList" :key="chart.name" :span="6">
            <a-card class="pbz-card" :bordered="false">
              <div class="pbz-card-title">
                <h3>{{ $t(`dashboardContent.${chart.name}`) }}</h3>
              </div>
              <a-row :gutter="6" class="pbz-card-legend">
                <a-col v-for="(legend, index) in chart.value.legends" :key="index" :span="12">
                  <span class="box-status-span" :style="{ backgroundColor: legend.color }">
                    &nbsp;
                  </span>
                  <span>{{ legend.label }}</span>
                </a-col>
              </a-row>
              <div class="dashboard-cart">
                <a-spin :spinning="chart.loading">
                  <doughnut-chart
                    :chart-data="chart.value.chartData"
                    :options="chart.value.chartOptions"
                  />
                </a-spin>
              </div>
            </a-card>
          </a-col>
        </a-row>

        <!-- Best Selling Product & Item -->
        <div class="pbz-top-10">
          <div v-for="list in bestSellingList" :key="list.id" class="pbz-top-10-card">
            <div>
              <h3>{{ $t(`dashboardContent.${list.name}`) }}</h3>
              <p>{{ $t(`dashboardContent.detail_${list.name}`) }}</p>
            </div>
            <a-spin :spinning="loading[list.name]">
              <table v-if="list.count > 0" class="pbz-table">
                <tr v-for="(i, idx) in 5" :key="i">
                  <td :style="{ width: '40%', borderBottom: list.values?.[idx]?.name ? '1px solid #e4e9f0' : 'none' }">
                    {{ list.values?.[idx]?.name }}
                  </td>
                  <td :style="{ width: '10%', borderBottom: list.values?.[idx]?.name ? '1px solid #e4e9f0' : 'none' }">
                    <b>{{
                      list.values?.[idx]?.name && list.values?.[idx]?.value
                        ? formatNumber(list.values?.[idx]?.value)
                        : ''
                    }}</b>
                  </td>
                  <td :style="{ width: '40%', borderBottom: list.values?.[idx]?.name ? '1px solid #e4e9f0' : 'none' }">
                    {{ list.values?.[idx + 5]?.name }}
                  </td>
                  <td :style="{ width: '10%', borderBottom: list.values?.[idx]?.name ? '1px solid #e4e9f0' : 'none' }">
                    <b>{{
                      list.values?.[idx + 5]?.name && list.values?.[idx + 5]?.value
                        ? formatNumber(list.values?.[idx + 5]?.value)
                        : ''
                    }}</b>
                  </td>
                </tr>
              </table>
              <div v-else class="text-center" style="padding: 40px 0">
                <b>{{ $t('dashboardContent.no_data') }}</b>
              </div>
            </a-spin>
          </div>
        </div>
      </section>
    </a-form-model>
  </div>
</template>

<script>
import { getBusiness } from '@/api/business'
import { getWarehouseList } from '@/api/warehouse'
import { getDashboardSkuAndOos } from '@/api/inventory'
import { getDashboardData } from '@/api/dashboard'

import DashboardSummary from '@/components/Dashboard/Summary'
import LineChart from '@/components/Dashboard/Chart/line.vue'
// import HorizontalChart from '@/components/Dashboard/Chart/horizontal.vue'
import DoughnutChart from '@/components/Dashboard/Chart/doughnut-v2.vue'

import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'

export default {
  name: 'Dashboard',
  components: {
    DashboardSummary,
    LineChart,
    // HorizontalChart,
    DoughnutChart,
  },
  data() {
    const pieChart = {
      legends: [],
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          displayColors: false,
          bodyFontSize: 16,
          callbacks: {
            label: ((tooltipItem, data) => {
              const labels = data.labels[tooltipItem.index]
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const currentValue = this.formatNumber(dataset.data[tooltipItem.index])
              const currentOrder = this.formatNumber(dataset.order[tooltipItem.index])
              const text = `${labels},${dataset.title === 'Sales' ? `Sales: ${currentValue},` : '' }Order: ${currentOrder}`
              return text.split(',')
            }),
          },
        },
        cutoutPercentage: 70,
      },
    }

    return {
      channelCode: '',
      channelOrder: 0,
      defaultFormat: 'YYYY-MM-DD',
      dateFormat: 'DD-MM-YYYY',
      ranges: {
        'Hari ini': [this.$moment(), this.$moment()], 
        'Bulan ini': [this.$moment().startOf('month'), this.$moment().endOf('month')],
        'Tahun ini': [this.$moment().startOf('year'), this.$moment().endOf('year')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days'), this.$moment()],
        '30 Hari Terakhir': [this.$moment().subtract(1, 'month'), this.$moment()],
        '1 Tahun Terakhir': [this.$moment().subtract(1, 'year'), this.$moment()],
      },
      form: {
        warehouse: [],
        report_date: [this.$moment().subtract(7, 'days'), this.$moment()],
        report_business: [],
        report_warehouse: [],
        report_channel: [],
        report_currency: 'IDR',
        report_comparison: [],
      },
      params: {
        start_date: '',
        end_date: '',
        currency_code: 'IDR',
        business_ids: undefined,
        warehouse_ids: undefined,
        channel_ids: undefined,
      },
      paramsToday: {
        start_date: '',
        end_date: '',
        currency_code: 'IDR',
        business_ids: undefined,
        warehouse_ids: undefined,
      },
      loading: {
        business: false,
        warehouse: false,
        channelList: false,
        status: false,
        sku: false,
        todaySummary: false,
        reportSummary: false,
        comparisonSummary: false,
        sales: false,
        province: false,
        city: false,
        product: false,
        catalog: false,
      },
      channelImages: {
        shopee_id: require('@/assets/logo/shopee.png'),
        tokopedia_id: require('@/assets/logo/tokopedia.png'),
        lazada: require('@/assets/logo/lazada.png'),
        blibli: require('@/assets/logo/blibli.png'),
        bukalapak: require('@/assets/logo/bukalapak.png'),
        distributor: require('@/assets/logo/distributorship.png'),
        store: require('@/assets/logo/webcommerce.png'),
      },
      chartList: [
        { name: 'channel', label: 'channelName', value: Object.assign({}, pieChart), loading: false },
        { name: 'warehouse', label: 'warehouseName', value: Object.assign({}, pieChart), loading: false },
        { name: 'courier', label: 'courierName', value: Object.assign({}, pieChart), loading: false },
        { name: 'payment_method', label: 'paymentMethodName', value: Object.assign({}, pieChart), loading: false },
      ],
      sales: {
        values: [
          { id: 1, name: 'GMV', prefix: 'Rp', value: 0, percent: 0 },
          { id: 2, name: 'NMV', prefix: 'Rp', value: 0, percent: 0 },
          { id: 3, name: 'Total Order', prefix: '', value: 0, percent: 0 },
          // { id: 4, name: 'Buyer', prefix: '', value: 0, percent: 0 },
        ],
        chartData: {
          labels: [],
          datasets: [],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: window.screen.width < 800 ? 'top' : 'right',
          },
          scales: {
            xAxes: [{ gridLines: { color: '#F0F0F0', display: false } }],
            yAxes: [{
              gridLines: { color: '#F0F0F0' },
              ticks: {
                callback: function(value) {
                  if(Math.floor(value) === value) return new Intl.NumberFormat('id-ID').format(value)
                },
              },
            }],
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: ((tooltipItems) => {
                const itemValue = tooltipItems.datasetIndex < 2
                  ? 'Rp ' + this.formatNumber((tooltipItems.yLabel * 100000).toFixed(2))
                  : this.formatNumber(tooltipItems.yLabel)
                return itemValue
              }),
            },
          },
        },
      },
      businessList: [],
      warehouseList: [],
      reportWarehouseList: [],
      channelList: [],
      currencyList: [
        { value: 'IDR', label: '🇮🇩 Indonesia - IDR (Rp)' },
      ],
      statusList: [
        {
          id: 1,
          name: 'new_',
          value: 0,
          color: '#1890ff',
          url: {
            fulfillment_state: 'created',
          },
        },
        {
          id: 2,
          name: 'on_process',
          value: 0,
          color: '#fa8c16',
          url: {
            fulfillment_state: 'confirmed,accepted',
          },
        },
        {
          id: 3,
          name: 'ready_to_ship',
          value: 0,
          color: '#722ed1',
          url: {
            fulfillment_state: 'good_issued',
            shipping_state: 'good_issued',
          },
        },
        {
          id: 4,
          name: 'shipped',
          value: 0,
          color: '#13c2c2',
          url: {
            fulfillment_state: 'good_issued',
            shipping_state: 'good_issued',
          },
        },
        {
          id: 5,
          name: 'delivered',
          value: 0,
          color: '#52c41a',
          url: {
            shipping_state: 'delivered',
          },
        },
        {
          id: 6,
          name: 'cancel_requested',
          value: 0,
          color: '#f5222d',
          url: {
            sales_state: 'CANCELREQUESTED',
            filter_category: 'cancelrequested',
            date_from: 'all',
            date_until: 'all',
          },
        },
      ],
      skuList: [
        { id: 1, name: 'sku', value: 0 },
        { id: 2, name: 'oos', value: 0 },
      ],
      summaryTodayList: [
        { id: 1, name: 'sales', prefix: 'Rp', suffix: '', value: 0, canceled: 0, percent: 0, icon: 'shopping' },
        { id: 2, name: 'logistic_expenses', prefix: 'Rp', suffix: '', value: 0, canceled: '', percent: 0, icon: 'car' },
        { id: 3, name: 'item_sold', prefix: '', suffix: 'item', value: 0, canceled: '', percent: 0, icon: 'gold'  },
      ],
      summaryList: [
        { id: 1, name: 'sales', prefix: 'Rp', suffix: '', value: 0, canceled: 0, percent: 0, icon: 'shopping' },
        { id: 2, name: 'logistic_expenses', prefix: 'Rp', suffix: '', value: 0, canceled: '', percent: 0, icon: 'car' },
        { id: 3, name: 'item_sold', prefix: '', suffix: 'item', value: 0, canceled: '', percent: 0, icon: 'gold'  },
      ],
      bestSellingList: [
        { id: 1, name: 'province', values: [], count: 0 },
        { id: 2, name: 'city', values: [], count: 0 },
        { id: 3, name: 'product', values: [], count: 0 },
        { id: 4, name: 'catalog', values: [], count: 0 },
      ],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
  },
  watch: {
    businessId() {
      this.init()
    },
    '$route.query.workspace_id'() {
      this.init()
    },
    '$route.query.warehouse_id'() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    detailOrder(name, url) {
      const path = name === 'cancel_requested'
        ? 'sales'
        : 'fulfillment'
      const value = name === 'cancel_requested'
        ? ['sales']
        : ['inventory', 'fulfillment']
      this.$router.push({
        path,
        query: {
          ...this.$route.query,
          ...url,
          page: name === 'cancel_requested'
            ? undefined
            : 1,
        },
      })
      .then(() => {
        if(name !== 'cancel_requested') {
          this.$store.commit('settings/CHANGE_SETTING', {
            setting: 'openedMenu',
            value: ['inventory'],
          })
        }

        this.$store.commit('settings/CHANGE_SETTING', {
          setting: 'selectedMenu',
          value,
        })
      })
    },
    formatNumber(value) {
      if(value) return new Intl.NumberFormat('id-ID').format(value)
      return 0
    },
    convertNumber(value) {
      return Math.abs(Number(value)) >= 1.0e+9
        ? Math.trunc(Math.abs(Number(value)) / 1.0e+9) + ' mil'
        : Math.abs(Number(value)) >= 1.0e+6
        ? Math.trunc(Math.abs(Number(value)) / 1.0e+6) + ' jt'
        : Math.abs(Number(value)) >= 1.0e+3
        ? Math.trunc(Math.abs(Number(value)) / 1.0e+3) + ' rb'
        : Math.abs(Number(value))
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0]?.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    checkDate() {
      if(!this.form.report_comparison.length) return ''

      const start_date = this.$moment(this.form.report_comparison[0]).format('YYYY-MM-DD')
      const end_date = this.$moment(this.form.report_comparison[1]).format('YYYY-MM-DD')
      return start_date === end_date
        ? this.$moment(this.form.report_comparison[0]).format('D MMM YYYY')
        : `${this.$moment(this.form.report_comparison[0]).format('D MMM')} - ${this.$moment(this.form.report_comparison[1]).format('ll')}`
    },
    disabledDate(current) {
      return current && current > this.$moment().endOf('day');
    },
    percentCalculation(value1, value2) {
      if(value2 == 0) return 0
      return ((value1 - value2) / value2) * 100
    },
    init: debounce(function() {
      this.form = {
        warehouse: this.$route.query.warehouse_id
          ? [this.$route.query.warehouse_id]
          : [],
        report_date: [this.$moment().subtract(7, 'days'), this.$moment()],
        report_business: this.businessId
          ? this.businessId
          : [],
        report_warehouse: this.$route.query.warehouse_id
          ? [this.$route.query.warehouse_id]
          : [],
        report_channel: [],
        report_currency: 'IDR',
        report_comparison: [],
      }

      this.fetchBusinessList()
      this.fetchWarehouseList()
      this.fetchChannels()
      this.initSummary()
      this.initReport()
    }, 500),
    initSummary() {
      this.paramsToday = {
        start_date: this.$moment().format(this.defaultFormat),
        end_date: this.$moment().format(this.defaultFormat),
        currency_code: 'IDR',
        business_ids: this.businessId,
        warehouse_ids: this.$route.query.warehouse_id
          ? this.$route.query.warehouse_id
          : this.form.warehouse.length
          ? this.form.warehouse.join()
          : undefined,
      }

      this.fetchStatus()
      this.fetchSkuAndOos()
      this.fetchSummary('today')
    },
    initReport() {
      this.params = {
        start_date: this.$moment(this.form.report_date[0]).format(this.defaultFormat),
        end_date: this.$moment(this.form.report_date[1]).format(this.defaultFormat),
        currency_code: this.form.report_currency,
        business_ids: this.businessId
          ? this.businessId
          : this.form.report_business.length
          ? this.form.report_business.join()
          : undefined,
        warehouse_ids: this.$route.query.warehouse_id
          ? this.$route.query.warehouse_id
          : this.form.report_warehouse.length
          ? this.form.report_warehouse.join()
          : undefined,
        channel_ids: this.form.report_channel.length
          ? this.form.report_channel.join()
          : undefined,
      }

      this.fetchSummary()
      this.fetchSalesOverview()
      this.fetchChart()
      this.fetchTop10()
    },
    async fetchBusinessList() {
      if(this.businessId) {
        const findBusiness = this.$store.state.user.businessList.find(businesses => businesses.business_id === this.businessId)
        this.businessList = findBusiness?.business_id
          ? [{ value: findBusiness.business_id, label: findBusiness.business_name }]
          : []
        return
      }
      
      this.loading.business = true
      await getBusiness({
        business_id: this.businessId,
      })
      .then(({ data: { data: businesses } }) => {
        this.businessList = businesses.map(business => ({
          value: business.business_id,
          label: business.business_name,
        }))
      })
      .catch(err => {
        console.error(err)
        this.businessList = []
      })
      .finally(() => this.loading.business = false)
    },
    async fetchWarehouseList(type = '') {
      this.loading.warehouse = true
      await getWarehouseList({
        business_id: type === 'report' && !this.businessId && this.form.report_business.length
          ? this.form.report_business.join()
          : this.businessId,
        warehouse_id: this.$route.query.warehouse_id,
      })
      .then(({ data: { data: warehouses } }) => {
        if(!type) {
          this.warehouseList = warehouses.map(warehouse => ({
            value: warehouse.id,
            label: warehouse.name,
          }))
        }

        this.reportWarehouseList = warehouses.map(warehouse => ({
          value: warehouse.id,
          label: warehouse.name,
        }))
      })
      .catch(err => {
        console.error(err)
        this.warehouseList = []
      })
      .finally(() => this.loading.warehouse = false)
    },
    async fetchChannels() {
      this.loading.channelList = true
      const getDashboard = () => import('@/api/dash.js')
      getDashboard()
      .then(async ({ default: { getDashboardChannels } }) => {
        await getDashboardChannels({
          business_ids: this.businessId
            ? this.businessId
            : this.form.report_business.length
            ? this.form.report_business.join()
            : undefined,
        })
        .then(({ data }) => {
          let channelList = []
          data.forEach(channel => {
            if(!channel?.channelTitle?.toLowerCase().includes('redeem')) {
              channelList.push({
                value: channel.id,
                label: channel.channelTitle,
              })
            }
          })
          this.channelList = channelList
        })
        .catch(err => {
          console.error(err)
          this.channelList = []
        })
        .finally(() => this.loading.channelList = false)
      })
      .catch(() => {
        this.channelList = []
        this.loading.channelList = false
      })
    },
    async fetchStatus() {
      this.loading.status = true
      await getDashboardData({
        url: 'order_status',
        params: {
          start_date: this.$moment().format(this.defaultFormat),
          end_date: this.$moment().format(this.defaultFormat),
          currency_code: 'IDR',
          business_ids: this.businessId,
          warehouse_ids: this.form.warehouse.length
            ? this.form.warehouse.join()
            : undefined,
        },
      })
      .then(({ data }) => {
        this.statusList = this.statusList.map(status => ({
          ...status,
          value: data[status.name] || 0,
        }))
      })
      .catch(err => {
        console.error(err)
        this.statusList = this.statusList.map(status => ({
          ...status,
          value: 0,
        }))
      })
      .finally(() => this.loading.status = false)
    },
    async fetchSkuAndOos() {
      this.loading.sku = true
      const params = {
        workspace_id: this.$route.query.workspace_id,
        business_id: this.businessId,
        warehouse_ids: this.form.warehouse.length
          ? this.form.warehouse.join()
          : undefined,
      }

      await Promise.all([getDashboardSkuAndOos(params), getDashboardSkuAndOos({ ...params, status: 'OUT_OF_STOCK' })])
      .then(([{ data: totalSku }, { data: totalOos }]) => {
        this.skuList = this.skuList.map((sku, index) => ({
          ...sku,
          value: (
            index == 0
              ? totalSku
              : totalOos
          ) || 0,
        }))
      })
      .catch(err => {
        console.error(err)
        this.skuList = this.skuList.map(sku => ({ ...sku, value: 0 }))
      })
      .finally(() => this.loading.sku = false)
    },
    async fetchSummary(type = '') {
      if(type === 'comparison' && !this.form.report_comparison.length) return

      let requests = []
      const loadingName = type === 'comparison'
        ? 'comparisonSummary'
        : type === 'today'
        ? 'todaySummary'
        : 'reportSummary'
      const paramsName = type === 'today'
        ? 'paramsToday'
        : 'params'
      const listName = type === 'today'
        ? 'summaryTodayList'
        : 'summaryList'
      const params = type === 'comparison'
        ? { 
            ...this[paramsName],
            start_date: this.$moment(this.form.report_comparison[0]).format(this.defaultFormat),
            end_date: this.$moment(this.form.report_comparison[1]).format(this.defaultFormat),
          }
        : this[paramsName]
      
      if(!type && this.form.report_comparison.length) this.loading.comparisonSummary = true

      this.loading[loadingName] = true
      const bodyVal = {
        url: 'summary',
        params,
      }

      requests.push(getDashboardData(bodyVal))
      if(type === 'today') {
        requests.push(getDashboardData({
          ...bodyVal,
          params: {
            ...bodyVal.params,
            start_date: this.$moment().subtract(1, 'days').format(this.defaultFormat),
            end_date: this.$moment().subtract(1, 'days').format(this.defaultFormat),
          },
        }))
      }

      await Promise.all(requests)
      .then(([{ data: summaries }, response ]) => {
        if(type !== 'comparison') {
          const sales = summaries.gmv || 0
          const canceled = summaries.gmvCancel || 0
          const logistic = summaries.logisticCost || 0
          const item = summaries.totalQty || 0

          let salesPercent = 0
          let logicticPercent = 0
          let itemPercent = 0
          if(type === 'today') {
            const { data: yesterdaySummaries } = response
            const salesYesterday = yesterdaySummaries.gmv || 0
            const logisticYesterday = yesterdaySummaries.logisticCost || 0
            const itemYesterday = yesterdaySummaries.totalQty || 0

            salesPercent = this.percentCalculation(sales, salesYesterday)
            logicticPercent = this.percentCalculation(logistic, logisticYesterday)
            itemPercent = this.percentCalculation(item, itemYesterday)
          }

          this[listName] = this[listName].map((summary, index) => ({
            ...summary,
            value: index == 0
              ? sales
              : index == 1
              ? logistic
              : item,
            canceled: index == 0
              ? canceled
              : '',
            percent: index == 0
              ? salesPercent
              : index == 1
              ? logicticPercent
              : itemPercent,
          }))

          if(!type) {
            this.sales.values[0].value = sales
            this.sales.values[1].value = summaries.nmv || 0
            this.sales.values[2].value = summaries.totalOrders || 0
          }
        } else {
          const gmv = summaries.gmv || 0
          const nmv = summaries.nmv || 0
          const order = summaries.totalOrders || 0

          this.sales.values[0].percent = this.percentCalculation(this.sales.values[0].value, gmv)
          this.sales.values[1].percent = this.percentCalculation(this.sales.values[1].value, nmv)
          this.sales.values[2].percent = this.percentCalculation(this.sales.values[2].value, order)
        }
      })
      .catch(err => {
        console.error(err)
        if(type !== 'comparison') {
          this[listName] = this[listName].map(summary => ({
            ...summary,
            value: 0,
            canceled: 0,
            percent: 0,
          }))
        } else {
          this.sales.values[0].percent = 0
          this.sales.values[1].percent = 0
          this.sales.values[2].percent = 0
        }
      })
      .finally(() => {
        this.loading[loadingName] = false
        if(!type && this.form.report_comparison.length) this.fetchSummary('comparison')
      })
    },
    async fetchSalesOverview() {
      this.loading.sales = true
      const startDate = this.$moment(this.params.start_date)
      const endDate = this.$moment(this.params.end_date)
      const dateDifference = endDate.diff(startDate, 'days')
      const newDateDifference = dateDifference < 7
        ? 7
        : dateDifference > 90
        ? endDate.diff(startDate, 'months')
        : dateDifference

      await getDashboardData({
        url: 'sales_overview',
        params: {
          ...this.params,
          start_date: dateDifference < 7
            ? this.$moment(this.params.end_date).subtract(7, 'days').format(this.defaultFormat)
            : this.params.start_date,
        },
      })
      .then(({ data: response = [] }) => {
        let labels = []
        let gmv = []
        let nmv = []
        let order = []
        const graphDateFormat = dateDifference > 90
          ? 'MMM YYYY'
          : 'll'

        if(newDateDifference > 30 && newDateDifference < 91) {
          let tempGmv = 0
          let tempNmv = 0
          let tempOrder = 0
          let count = 0
          let week = 1
          for(let i = 0; i <= newDateDifference; i++) {
            const newDate = this.$moment(startDate).add(i, 'days').format('YYYYMMDD')
            const findDate = response.find(res => res.dateInt == newDate)
            const salesGmv = findDate?.dateInt && findDate?.salesAggr != 0
              ? findDate.salesAggr / 100000
              : 0
            const salesNmv = findDate?.dateInt && findDate?.nmvAggr != 0
              ? findDate.nmvAggr / 100000
              : 0
            const salesOrder = findDate?.dateInt
              ? findDate.totalOrderAggr
              : 0
            tempGmv += salesGmv
            tempNmv += salesNmv
            tempOrder += salesOrder
            count++

            if(count % 7 == 0 || i == newDateDifference) {
              labels.push('Week ' + week)
              gmv.push(tempGmv)
              nmv.push(tempNmv)
              order.push(tempOrder)
              tempGmv = 0
              tempNmv = 0
              tempOrder = 0
              count = 0
              week++
            }
          }
        } else {
          if(response && response.length < newDateDifference) {
            const subtractType = dateDifference > 90
              ? 'months'
              : 'days'
            const newDateFormat = dateDifference > 90
              ? 'YYYYMM'
              : 'YYYYMMDD'
            
            for(let i = 0; i <= newDateDifference; i++) {
              const newDate = this.$moment(endDate).subtract(i, subtractType).format(newDateFormat)
              const findDate = response.find(res => res.dateInt == newDate)
              const dateLabel = findDate?.dateInt
                ? findDate.dateInt.toString()
                : newDate
              const salesGmv = findDate?.dateInt && findDate?.salesAggr != 0
                ? findDate.salesAggr / 100000
                : 0
              const salesNmv = findDate?.dateInt && findDate?.nmvAggr != 0
                ? findDate.nmvAggr / 100000
                : 0
              const salesOrder = findDate?.dateInt
                ? findDate.totalOrderAggr
                : 0
              
              labels.unshift(this.$moment(dateLabel).format(graphDateFormat))
              gmv.unshift(salesGmv)
              nmv.unshift(salesNmv)
              order.unshift(salesOrder)
            }
          } else {
            response.forEach(sales => {
              const salesGmv = sales.salesAggr != 0
                ? sales.salesAggr / 100000
                : 0
              const salesNmv = sales.nmvAggr != 0
                ? sales.nmvAggr / 100000
                : 0
              labels.push(this.$moment(sales.dateInt.toString()).format(graphDateFormat))
              gmv.push(salesGmv)
              nmv.push(salesNmv)
              order.push(sales.totalOrderAggr)
            })
          }
        }

        this.sales.chartData = {
          labels,
          datasets: [
            {
              label: 'GMV (1:100K)',
              data: gmv,
              backgroundColor: '#f48f7b',
              borderColor: '#f48f7b',
              fill: false,
            },
            {
              label: 'NMV (1:100K)',
              data: nmv,
              backgroundColor: '#7bf48f',
              borderColor: '#7bf48f',
              fill: false,
            },
            {
              label: 'Total Order',
              data: order,
              backgroundColor: '#7be0f4',
              borderColor: '#7be0f4',
              fill: false,
            },
          ],
        }
      })
      .catch(err => {
        console.error(err)
        this.sales.chartData = {
          labels: [],
          datasets: [],
        }
      })
      .finally(() => this.loading.sales = false)
    },
    async fetchChart() {
      this.channelCode = ''
      this.chartList.forEach(async chart => {
        chart.loading = true
        await getDashboardData({
          url: `order_by_${chart.name}`,
          params: {
            ...this.params,
            limit: 1000,
          },
        })
        .then(({ data: response }) => {
          let legends = []
          let labels = []
          let backgroundColor = []
          let data = []
          let order = []
          const chartObj = ['courier', 'payment_method'].includes(chart.name) ? 'countAggr' : 'salesAmount'
          const sortedArray = response && response.length
            ? orderBy(response, [chartObj], ['desc'])
            : []
          sortedArray.forEach((list, index) => {
            if(index < 10) {
              const dataValue = list[chartObj]
              const name = chart.name === 'payment_method'
                ? 'paymentMethodName'
                : `${chart.name}Name`
              const label = list[name] || 'No Name'
              const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase()
              legends.push({
                label,
                color: randomColor,
              })
              labels.push(label)
              backgroundColor.push(randomColor)
              data.push(dataValue)
              order.push(list.countAggr)
            }
          })

          chart.value.legends = legends
          chart.value.chartData = {
            labels,
            datasets: [{
              label: 'Order',
              backgroundColor,
              data,
              order,
              title: chartObj === 'countAggr' ? 'Order' : 'Sales',
              barThickness: 8,
            }],
          }
        })
        .catch(err => {
          console.error(err)
          chart.value.legends = []
          chart.value.chartData = {
            labels: [],
            datasets: [],
          }
        })
        .finally(() => chart.loading = false)
      })
    },
    async fetchTop10() {
      this.bestSellingList.forEach(async bestSelling => {
        this.loading[bestSelling.name] = true
        await getDashboardData({
          url: `order_by_${bestSelling.name}`,
          params: this.params,
        })
        .then(({ data }) => {
          let values = []
          const sortedArray = data && data.length
            ? orderBy(data, ['countAggr'], ['desc'])
            : []
          sortedArray.forEach(list => {
            if(['product', 'catalog'].includes(bestSelling.name)) {
              const index = values.findIndex(val => val.id === list[`${bestSelling.name}Id`])
              if(index > -1) {
                values[index].value = +values[index].value + +list.countAggr
              } else {
                values.push({
                  id: list[`${bestSelling.name}Id`],
                  name: list[`${bestSelling.name}Name`] || 'No Name',
                  value: list.countAggr,
                })
              }
            } else {
              values.push({
                id: list[`${bestSelling.name}Id`],
                name: list[`${bestSelling.name}Name`] || 'No Name',
                value: list.countAggr,
              })
            }
          })
          bestSelling.values = values
          bestSelling.count = data?.length || 0
        })
        .catch(err => {
          console.error(err)
          bestSelling.values = []
          bestSelling.count = 0
        })
        .finally(() => this.loading[bestSelling.name] = false)
      })
    },
  },
}
</script>

<style>
  @media (min-width: 1310px) and (max-width: 1419px) {
    .ant-select-dropdown-menu-item {
      padding: 4px 10px !important;
      font-size: 12px !important;
      line-height: 19px !important;
    }
  }

  @media (max-width: 1309px) {
    .ant-select-dropdown-menu-item {
      padding: 3px 8px !important;
      font-size: 10px !important;
      line-height: 15px !important;
    }
  }
</style>
<style lang="scss">
  @import './dashboard.scss'
</style>